import type { MapRef, ViewState } from 'react-map-gl'

import { Backdrop, Box, CircularProgress } from '@mui/material'
import bbox from '@turf/bbox'
import MapPopupParty from 'components/Map/Popup/Party'
import 'mapbox-gl/dist/mapbox-gl.css'
import LegendControl from 'mapboxgl-legend'
import 'mapboxgl-legend/dist/style.css'
import { initialMapHoverInfoState, mapHoverInfoState } from 'pages/Core/atoms'
import { getIfAreaSelected } from 'pages/Core/helper'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import MapGL, {
  AttributionControl,
  FullscreenControl,
  NavigationControl,
  ScaleControl,
} from 'react-map-gl'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { getHeadersAuth } from 'services/axios.service'

import { mySearchState } from '../../atoms'
import ControlPanel from './ControlPanel'
import MAP_STYLE from './mapStyle'

const legendOptions = {
  layers: {
    apl: {
      attributes: ['fill-color'],
      highlight: false,
    },
    'zonage-ars': {
      attributes: ['fill-color'],
      highlight: true,
    },
  },
}

const initialViewState = {
  latitude: 46.75,
  longitude: 2.85,
  zoom: 5,
} as ViewState

const Map = ({ isLoading }: { isLoading: boolean }) => {
  const [cursor, setCursor] = useState<string>('auto')

  const refLegend = useRef(new LegendControl(legendOptions))
  const [mapStyle, setMapStyle] = React.useReducer(
    (state: any, updates: any) => ({ ...state, ...updates }),
    MAP_STYLE,
  )
  const mySearch = useRecoilValue(mySearchState)
  const [viewState, setViewState] = useState(initialViewState)

  const hoverInfo = useRecoilValue(mapHoverInfoState)
  const setHoverInfo = useSetRecoilState(mapHoverInfoState)
  const navigate = useNavigate()

  const mapRef = useRef<MapRef>(null)

  const ifAreaSelected = getIfAreaSelected(mySearch.options)

  useEffect(() => {
    if (!ifAreaSelected) {
      setViewState(initialViewState)
    }
  }, [ifAreaSelected])

  const updateLegend = async () => {
    mapRef.current?.getMap().removeControl(refLegend.current)
    refLegend.current = new LegendControl(legendOptions)
    mapRef.current?.getMap().addControl(refLegend.current, 'bottom-right')
  }

  const onClick = (event: any) => {
    const feature = event?.features?.[0]
    const mapZoom = mapRef.current?.getZoom()
    if (mapZoom && mapZoom > 10) {
      if (feature?.geometry) {
        const clusterId = feature?.properties?.clustered
        if (clusterId) {
          const poly = bbox(feature?._geometry)
          if (mapRef) {
            mapRef.current?.getZoom()
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            mapRef.current?.fitBounds(poly, {
              maxZoom: mapRef.current?.getZoom() + 3,
            })
          }
        } else {
          navigate(`/party/${feature.properties.partyId}`)
        }
      }
    }
  }

  const onMouseMove = useCallback((event: any) => {
    const geo = event.features && event.features[0]
    const mapZoom = mapRef.current?.getZoom()
    if (mapZoom && mapZoom > 10 && geo?.layer?.id) {
      setCursor('pointer')
      const { lat, lng } = event.lngLat
      setHoverInfo({
        coordinates: [lng, lat],
        properties: geo?.properties,
      })
    } else {
      setCursor('auto')
    }
  }, [])

  const onMouseLeave = useCallback(() => {
    if (hoverInfo.properties) {
      setHoverInfo(initialMapHoverInfoState)
    }
  }, [hoverInfo])

  return (
    <Box position="relative" style={{ height: '100%', width: '100%' }}>
      <Backdrop
        open={isLoading}
        sx={{
          color: '#fff',
          position: 'absolute',
          zIndex: (theme) => theme.zIndex.drawer,
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <MapGL
        {...viewState}
        doubleClickZoom
        styleDiffing
        attributionControl={false}
        cursor={cursor}
        interactiveLayerIds={['parties']}
        mapboxAccessToken="pk.eyJ1IjoiYnNvZ2hpZ2lhbiIsImEiOiJjazBhOTUxam4wMDNtM2RvNXJzbjQ5aGV6In0.eL8NJ0-ikx_5Dl49994bGw"
        mapStyle={mapStyle}
        minZoom={3}
        ref={mapRef}
        style={{ height: '100%', width: '100%' }}
        onClick={onClick}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
        onMove={(evt) => setViewState(evt.viewState)}
        transformRequest={(url, resourceType) => {
          if (
            resourceType === 'Tile' &&
            !url.startsWith('https://api.mapbox.com')
          ) {
            return {
              headers: getHeadersAuth(),
              url: url,
            }
          }
          return { url }
        }}
      >
        <ControlPanel
          mapRef={mapRef}
          setMapStyle={setMapStyle}
          updateLegend={updateLegend}
        />
        <AttributionControl compact customAttribution="Medtrucks 2023" />
        <FullscreenControl />
        <NavigationControl />
        <ScaleControl />
        {hoverInfo?.properties && (
          <MapPopupParty
            coordinates={hoverInfo.coordinates}
            properties={hoverInfo.properties}
          />
        )}
      </MapGL>
    </Box>
  )
}

export default Map
