import { Button, Typography } from '@mui/material'
import { RowSelectionState } from '@tanstack/react-table'
import { PartyType } from 'constants/enum'
import { reduce, xorBy } from 'lodash'
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table'
import { MRT_Localization_FR } from 'material-react-table/locales/fr'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { PartyOS } from 'types/party'

import { pageSize } from '../..'
import { myDiagnosticState } from '../../atoms'

const PartyList = ({
  isLoading,
  pagination,
  response,
  setPagination,
}: {
  isLoading: boolean
  pagination: MRT_PaginationState
  response: any
  setPagination: any
}) => {
  const navigate = useNavigate()
  const setMyDiagnostic = useSetRecoilState(myDiagnosticState)
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({}) //manage your own row selection state

  useEffect(() => {
    const selectedRows = table
      .getSelectedRowModel()
      .rows.map((row) => row.original)
    const newPerson = reduce(
      selectedRows,
      (acc: any, current: any) => {
        const key =
          current.data.partyType === PartyType.ORGANIZATION
            ? 'organization'
            : 'person'
        return {
          ...acc,
          [key]: xorBy(
            acc?.[key],
            [
              {
                category: current.data.category,
                label: current.data.name,
                value: current.data.partyId,
              },
            ],
            'value',
          ),
        }
      },
      { organization: [], person: [] },
    )
    setMyDiagnostic((curr) => ({
      ...curr,
      party: newPerson,
    }))
  }, [rowSelection])

  const onClickRow = (id: string) => {
    navigate(`/party/${id}`)
  }

  const columns: MRT_ColumnDef<{ data: PartyOS }>[] = [
    {
      Cell: ({ row }) => (
        <>
          <Button
            variant="text"
            onClick={() => onClickRow(row?.original?.data.partyId)}
          >
            <Typography color="primary" sx={{ textDecoration: 'underline' }}>
              {row?.original.data.name}
            </Typography>
          </Button>
          <Typography>{row?.original.data.partyId}</Typography>
        </>
      ),
      accessorFn: (row) => row.data.name,
      filterVariant: 'autocomplete',
      header: 'Nom / Code',
    },
    {
      accessorFn: (row) => row.data.category,
      header: "Profession ou type d'établissement",
    },
    {
      accessorFn: (row) => row.data.address,
      header: 'Adresse',
    },
  ]

  const table = useMaterialReactTable({
    columns,
    data: response?.data || [],
    enableColumnActions: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableGlobalFilter: false,
    enableHiding: false,
    enablePagination: true,
    enableRowSelection: true,
    enableSorting: false,
    enableStickyFooter: true,
    enableStickyHeader: true,
    enableTopToolbar: false,
    getRowId: (row) => row?.data?.partyId,
    initialState: {
      pagination: { pageIndex: 0, pageSize },
    },
    localization: MRT_Localization_FR,
    manualPagination: true,
    muiPaginationProps: {
      rowsPerPageOptions: [pageSize],
      variant: 'outlined',
    },
    muiTableContainerProps: { sx: { height: 'calc(100% - 70px)' } },
    muiTablePaperProps: { sx: { height: '100%' } },
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    paginationDisplayMode: 'pages',
    positionActionsColumn: 'last',
    positionToolbarAlertBanner: 'top',
    rowCount: response?.totalValue,
    state: {
      isLoading,
      pagination,
      rowSelection,
    },
  })

  return <MaterialReactTable table={table} />
}

export default PartyList
