import { Tabs } from '@mui/material'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import * as React from 'react'

import SelectResults from './Results'
import SelectSelections from './Selections'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, index, value, ...other } = props

  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

export default function SelectTabs({
  loadMore,
  nbItemsLoadMore,
  onChangeResultSelect,
  onChangeSelectionSelect,
  options,
  optionsSelected,
  totalRecords,
  updateOptionsWanted,
}: {
  loadMore?: any
  nbItemsLoadMore: number
  onChangeResultSelect: any
  onChangeSelectionSelect: any
  options: any
  optionsSelected: any
  totalRecords: any
  updateOptionsWanted: any
}) {
  const [tabIndex, setTabIndex] = React.useState(0)

  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)
  }

  const nbOptionsSelected = optionsSelected.length

  const labelSelection =
    nbOptionsSelected > 0 ? `SELECTION (${nbOptionsSelected})` : 'SELECTION'

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          aria-label="select tabs"
          value={tabIndex}
          onChange={handleChangeTabs}
        >
          <Tab label="RESULTAT" />
          <Tab disabled={nbOptionsSelected === 0} label={labelSelection} />
        </Tabs>
      </Box>

      <CustomTabPanel index={0} value={tabIndex}>
        <SelectResults
          loadMore={loadMore}
          nbItemsLoadMore={nbItemsLoadMore}
          options={options}
          optionsSelected={optionsSelected}
          totalRecords={totalRecords}
          updateOptionsWanted={updateOptionsWanted}
          onChangeResultSelect={onChangeResultSelect}
        />
      </CustomTabPanel>
      <CustomTabPanel index={1} value={tabIndex}>
        <SelectSelections
          optionsSelected={optionsSelected}
          setTabIndex={setTabIndex}
          onChangeSelectionSelect={onChangeSelectionSelect}
        />
      </CustomTabPanel>
    </Box>
  )
}
