import { Box, Divider, Grid } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import Filters from 'components/Filters/AsideFilters'
import Chips from 'components/Filters/Chips'
import { MRT_PaginationState } from 'material-react-table'
import React, { useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { formattedFilterParams } from '../helper'
import Tabs from './Tabs'
import {
  defaultMySearchState,
  myPartyListState,
  mySearchState,
  nextMySearchState,
} from './atoms'

export const pageSize = 50

const PartyPage = () => {
  const mySearch = useRecoilValue(mySearchState)
  const setMyPartyListState = useSetRecoilState(myPartyListState)

  const [tabState, setTabState] = useState(0)

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize,
  })

  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery({
    queryFn: async () => {
      const { data } = await axios.post('/opensearch/search/party', {
        from: pagination.pageSize * pagination.pageIndex,
        myFilters: formattedFilterParams(mySearch.options),
        size: pagination.pageSize,
      })
      return data
    },
    queryKey: [`opensearch-search-party`, mySearch, pagination],
  })

  useEffect(() => {
    setMyPartyListState({
      party: response?.data?.map((data: any) => data?.data.partyId) || [],
    })
  }, [response])

  return (
    <Box display="flex" flexGrow={1} sx={{ overflow: 'hidden' }}>
      <Grid container flexDirection="row" m={0} wrap="nowrap">
        <Grid
          item
          direction="column"
          display="flex"
          minWidth="400px"
          position="relative"
          width="400px"
        >
          <Filters
            defaultMyFiltersState={defaultMySearchState}
            myFiltersState={mySearchState}
            nextMyFiltersState={nextMySearchState}
            refetchSubmit={refetch}
          />
        </Grid>
        <Divider orientation="vertical" />
        <Grid
          item
          direction="column"
          display="flex"
          flexGrow={1}
          sx={{ overflow: 'hidden', padding: '0px' }}
        >
          <Chips
            myFiltersState={mySearchState}
            nextMyFiltersState={nextMySearchState}
            refetchSubmit={refetch}
          />
          <Tabs
            count={response?.totalValue}
            isLoading={isLoading}
            pagination={pagination}
            response={response}
            setPagination={setPagination}
            setTabState={setTabState}
            tabState={tabState}
          ></Tabs>
        </Grid>
      </Grid>
    </Box>
  )
}

export default PartyPage
