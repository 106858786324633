import { Typography } from '@mui/material'
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table'
import { MRT_Localization_FR } from 'material-react-table/locales/fr'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PartyOS } from 'types/party'

import { pageSize } from '../..'

const columns: MRT_ColumnDef<{ data: PartyOS }>[] = [
  {
    Cell: ({ row }) => (
      <>
        <Typography color="primary" sx={{ textDecoration: 'underline' }}>
          {row?.original.data.name}
        </Typography>
        <Typography>{row?.original.data.partyId}</Typography>
      </>
    ),
    accessorFn: (row) => row.data.name,
    filterVariant: 'autocomplete',
    header: 'Nom / Code',
  },
  {
    accessorFn: (row) => row.data.category,
    header: "Profession ou type d'établissement",
  },
  {
    accessorFn: (row) => row.data.address,
    header: 'Adresse',
  },
]

const PartyList = ({
  isLoading,
  pagination,
  response,
  setPagination,
}: {
  isLoading: boolean
  pagination: MRT_PaginationState
  response: any
  setPagination: any
}) => {
  const navigate = useNavigate()

  const onClickRow = (id: string) => {
    navigate(`/party/${id}`)
  }

  const table = useMaterialReactTable({
    columns,
    data: response?.data || [],
    enableColumnActions: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableGlobalFilter: false,
    enableHiding: false,
    enablePagination: true,
    enableSorting: false,
    enableStickyFooter: true,
    enableStickyHeader: true,
    enableTopToolbar: false,
    getRowId: (row) => row?.data?.partyId,
    initialState: {
      pagination: { pageIndex: 0, pageSize },
    },
    localization: MRT_Localization_FR,
    manualPagination: true,
    muiPaginationProps: {
      rowsPerPageOptions: [pageSize],
      variant: 'outlined',
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => onClickRow(row?.original?.data.partyId),
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      },
    }),
    muiTableContainerProps: { sx: { height: 'calc(100% - 70px)' } },
    muiTablePaperProps: { sx: { height: '100%' } },
    onPaginationChange: setPagination,
    paginationDisplayMode: 'pages',
    positionActionsColumn: 'last',
    positionToolbarAlertBanner: 'top',
    rowCount: response?.totalValue,
    state: {
      isLoading,
      pagination,
    },
  })

  return <MaterialReactTable table={table} />
}

export default PartyList
